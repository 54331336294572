import React, { Fragment } from "react"
import { graphql } from "gatsby"

import Spacer from "../components/Spacer"
import CaseStudyBox from "../components/CaseStudyBox"
import Seo from "../components/Seo"
import PageHeader from "../components/PageHeader"
import CallToAction from "../components/CallToAction"
import BlogPreview from "../components/BlogPreview"
import Pagination from "../components/Pagination"
import CategoryTabs from "../components/CategoryTabs"

export const pageData = graphql`
  query($postIds: [String]) {
    allWpCaseStudy(
      filter: { id: { in: $postIds } }
      sort: { order: DESC, fields: date }
    ) {
      nodes {
        id
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
              publicURL
            }
            mimeType
          }
        }
        title
        slug
        caseStudy {
          clientIndustry
          customerType
          summary
        }
        categories {
          nodes {
            name
          }
        }
      }
    }
    wpPage(title: { eq: "Work" }) {
      pageHeaderFields {
        headerContent
      }
      seo {
        metaDesc
        title
        opengraphImage {
          localFile {
            publicURL
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
      callToActionFields {
        callToAction {
          button {
            label
            link {
              url
            }
          }
          content
        }
      }
    }
  }
`

export default function CaseStudyArchiveTempalte({
  data: {
    wpPage: {
      seo,
      featuredImage,
      pageHeaderFields: { headerContent },
      callToActionFields: { callToAction },
    },
    allWpCaseStudy: { nodes: caseStudies },
  },

  pageContext: { totalPages, currentPage, currentCategory, categories },
}) {
  //chunk case studies into arrays of 3 for displaying correctly in grid
  const posts = caseStudies.reduce(
    (r, e, i) => (i % 3 ? r[r.length - 1].push(e) : r.push([e])) && r,
    []
  )

  return (
    <>
      <Seo
        seo={{
          ...seo,
          featuredImage: featuredImage?.node?.localFile?.publicURL,
        }}
      />
      <PageHeader headerContent={headerContent} />
      <Spacer size={32} when={{ md: 48 }} />
      <CategoryTabs currentCategory={currentCategory} categories={categories} />
      <Spacer size={24} when={{ md: 40 }} />
      <div className="container">
        <div className="row">
          {posts.map((caseStudyGroup, index) => (
            <Fragment key={index}>
              {caseStudyGroup.map((caseStudy, caseStudyIndex) => {
                if (caseStudyIndex === 0) {
                  return (
                    <div className="col-12" key={caseStudy.id}>
                      <CaseStudyBox caseStudy={caseStudy} tall />
                      <Spacer size={24} />
                    </div>
                  )
                } else if (caseStudyIndex === 1) {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-8"
                      key={caseStudy.id}
                    >
                      <CaseStudyBox caseStudy={caseStudy} />
                      <Spacer size={24} />
                    </div>
                  )
                } else {
                  return (
                    <div
                      className="col-12 col-md-6 col-lg-4"
                      key={caseStudy.id}
                    >
                      <CaseStudyBox caseStudy={caseStudy} />
                      <Spacer size={24} />
                    </div>
                  )
                }
              })}
            </Fragment>
          ))}
        </div>
        {totalPages > 1 && (
          <div className="row">
            <div className="col-12">
              <Pagination
                prevTo={
                  currentPage === 2
                    ? `${currentCategory.slug}/`
                    : `${currentCategory.slug}/${currentPage - 1}/`
                }
                prevActive={currentPage !== 1 ? 1 : 0}
                nextTo={`${currentCategory.slug}/${currentPage + 1}`}
                nextActive={currentPage !== totalPages ? 1 : 0}
              />
            </div>
          </div>
        )}
      </div>
      <Spacer size={40} when={{ md: 56 }} />
      <CallToAction content={callToAction} />
      <Spacer size={32} when={{ md: 48 }} />
      <BlogPreview />
    </>
  )
}
